import { Box, Typography, Link, Chip, Grid } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Link as RouterLink } from 'react-router-dom';
import TextWithLinks from './TextWithLInks';
import ArtistsList from './ArtistsList';
import { AddToGoogleCalendarButton, TicketButton, formatDate } from './Utils';
import { Event as EventType, Tag, Venue } from '../types';
import CustomAuthProvider from './admin/CustomAuthProvider';

const EventCard = ({ event: evt }: { event: EventType }) => {
  return (
    <Grid container spacing={1} mt={0} flex={1}>
      <Grid item xs={12} sx={{ pt: '0 !important' }} flex={1}>
        <Typography
          variant="h5"
          sx={{
            color: (theme) => theme.palette.ctaColor?.main || '#000',
            lineHeight: '1.25em',
            mt: 0,
          }}
        >
          {evt.title}

          {CustomAuthProvider.isAdmin() && (
            <small>
              {' '}
              <Link
                variant="button"
                href={`/manage/Events/${evt.id}`}
                target="_blank"
                color="secondary"
              >
                <EditIcon sx={{ fontSize: '14px' }} />
              </Link>
            </small>
          )}
        </Typography>
        <Typography variant="h6" mb={0}>
          Event Details:
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          gap={1}
          mb={1}
        >
          {evt.tags.map((tag: Tag) => (
            <Chip key={tag.id} label={tag.name} size="small" />
          ))}
        </Box>
        {evt.venue && 
          <Box display="flex" alignItems="center" my={0}>
            <Typography variant="body2">Where:&nbsp;</Typography>
            <Link
              component={RouterLink}
              underline="hover"
              to={`/venue/${evt.venue.id}`}
              px={0}
              sx={{
                color: (theme) => theme.palette.ctaColor?.main,
                fontFamily: '"kon-tiki-aloha-jf", sans-serif',
              }}
            >
              {evt.venue.name}, {evt.venue.city}, {evt.venue.state}
            </Link>
            <LocationOnIcon fontSize="small" sx={{ mr: 0.5 }} />
          </Box>
        }
        <Box display="flex" alignItems="center">
          <Typography variant="body2">
            When:&nbsp;
            <Typography
              display="inline"
              variant="body2"
              color="textSecondary"
              ml={0.5}
            >
              {`${formatDate(evt.datetime_start)} - ${formatDate(
                evt.datetime_end,
                evt.datetime_start,
              )}`}
            </Typography>
            &nbsp;
          </Typography>
          <ScheduleIcon fontSize="small" sx={{ mr: 0.5 }} />
        </Box>
        <Box>
          <Typography
            variant="body2"
            color="textPrimary"
            alignItems="center"
            display="flex"
          >
            Add to calendar:&nbsp;
            <AddToGoogleCalendarButton event={evt} />
          </Typography>
        </Box>
        {evt.cost && (
          <Box display="flex" alignItems="center" alignContent="center">
            <Box>
              <Typography variant="body2" color="textPrimary">
                Cost:
                <Typography
                  display="inline"
                  variant="body2"
                  color="textSecondary"
                  ml={0.5}
                >
                  {evt.cost}
                </Typography>
              </Typography>
            </Box>
          </Box>
        )}
        {evt.tickets_url && (
          <Box>
            <Typography
              variant="body2"
              color="textPrimary"
              alignItems="center"
              display="flex"
            >
              Buy Tickets:&nbsp;
              <TicketButton event={evt} />
            </Typography>
          </Box>
        )}
        {evt.website && (
          <Box>
            <Typography
              variant="body2"
              color="textSecondary"
              display="flex"
              alignItems="center"
              flexDirection="row"
            >
              <Typography
                variant="body2"
                color="textPrimary"
                alignItems="center"
                display="flex"
              >
                Website: &nbsp;
                <Link
                  href={evt.website}
                  className="limited-text"
                  display="inline-block"
                  sx={{ color: (theme) => theme.palette.ctaColor?.main }}
                >
                  {evt.website}
                </Link>
              </Typography>
            </Typography>
          </Box>
        )}
        <Typography variant="body2" color="textPrimary" mb={0}>
          Description:
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          pr={2}
          className="display-linebreak"
        >
          <TextWithLinks textValue={evt.description} />
        </Typography>
        <EventVenueDetails venue={evt.venue} />
        <ArtistsList artists={evt.artists} />
      </Grid>
    </Grid>
  );
};
const EventVenueDetails = ({ venue }: { venue: Venue }) => {
  if(!venue){ return null; }
  return (
    <Grid container spacing={1} mt={0}>
      <Grid item xs={12}>
        <Typography variant="h6" mb={0}>
          Venue Details:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {venue.description && (
          <Typography variant="body2" color="textSecondary">
            Description: <TextWithLinks textValue={venue.description} />
          </Typography>
        )}
        <Typography variant="body2" color="textSecondary">
          Address:{' '}
          <Link
            href={`https://www.google.com/maps/search/?api=1&query=${venue.geocoded_address}`}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: (theme) => theme.palette.ctaColor?.main }}
          >
            {venue.geocoded_address}
          </Link>
        </Typography>
        {venue.phone && (
          <Typography variant="body2" color="textSecondary">
            Phone: {venue.phone}
          </Typography>
        )}
        {venue.website && (
          <Typography
            variant="body2"
            color="textSecondary"
            display="flex"
            alignItems="center"
            flexDirection="row"
          >
            Venue Website:&nbsp;
            <Link
              href={venue.website}
              className="limited-text"
              display="inline-block"
              sx={{ color: (theme) => theme.palette.ctaColor?.main }}
            >
              {venue.website}
            </Link>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default EventCard;
