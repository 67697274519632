import { Box, Container, Grid } from '@mui/material';
import { EventsList } from '../components/EventsList';
import TopArtists from '../components/TopArtists';
import TopVenues from '../components/TopVenues';
import { Event as EventType } from '../types';
import {  EventFilter } from '../components/EventFilter';
import { CTAButton, useEvents } from '../components/Utils';

export type GroupedEventsType = {
  weekendEvents?: EventType[];
  upcomingEvents?: EventType[];
  featuredEvents?: EventType[];
  pastEvents?: EventType[];
}


export const Main = () => {
  const { filters, setFilters, events } = useEvents();
  return (
    <Box>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <CTAButton href="/contact#contact-us" text="List Your Event!" fullWidth mb={2} />
            <EventFilter filters={filters} setFilters={setFilters} />
            <Box sx={{display: {xs: "none", md: "inherit"}}}>
              <TopArtists />
              <Box sx={{borderBottom: (theme) => `4px double ${theme?.palette?.ctaColor?.main}`, mb: 2}}></Box>
              <TopVenues />
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <EventsList events={events} filters={filters || {}} />
          </Grid>
          <Grid item xs={12} sx={{display: {xs: "inherit", md: "none"}}}>
            <Box width="100%">
              <Box sx={{borderBottom: (theme) => `4px double ${theme?.palette?.ctaColor?.main}`, mb: 2}}></Box>
              <TopArtists />
              <Box sx={{borderBottom: (theme) => `4px double ${theme?.palette?.ctaColor?.main}`, mb: 2}}></Box>
              <TopVenues />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
