import React, { useCallback } from 'react';
import { useNotify } from 'react-admin';
import { ImageInput } from 'react-admin';
import { ADMIN_API_ROOT } from './admin/CustomDataProvider';

export const AiAnalyzeImageInput = (props: any) => {
  const { dataCallback, ...otherProps } = props;
  const notify = useNotify();

  const handleFileChange = useCallback(
    async (file) => {
      notify('Poster AI Analysis started, please wait...', {
        type: 'info',
        autoHideDuration: null,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      if (file?.path) {
        const formData = new FormData();
        formData.append('file', file);

        try {
          const response = await fetch(`${ADMIN_API_ROOT}/events/ai-analyze`, {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: `bearer ${localStorage.getItem('access_token')}`,
            },
          });

          const data = await response.json();
          if (dataCallback) {
            dataCallback(data);
          }

          // Optionally, process the response data as needed
          notify('Poster AI analysis complete.', {
            type: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
        } catch (error) {
          console.error('Error uploading image:', error);
          notify(`Error analyzing image. ${error}`, {
            type: 'warning',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
        }
      }
    },
    [notify, dataCallback],
  );
  return (
    <ImageInput {...otherProps} accept="image/*" onChange={handleFileChange}>
      {otherProps.children}
    </ImageInput>
  );
};
