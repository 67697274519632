import { IconButton, Link, ButtonProps, Button } from '@mui/material';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import { dateFormat } from '../App';
import { Event as EventType } from '../types';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { GroupedEventsType } from '../pages/Main';
import EventsService from '../services/Events';
import groupEventsByUpcoming from '../utilities/Events';
import { FiltersType, DefaultFilters } from './EventFilter';

export const isSameDay = (date1Str: string, date2Str: string) => {
  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const formatGoogleCalendarDate = (date: string) => {
  const eventDate = new Date(date);
  return encodeURIComponent(eventDate.toISOString().replace(/-|:|\.\d+/g, ''));
};

export const createGoogleCalendarLink = (event: EventType) => {
  const startTime = formatGoogleCalendarDate(event.datetime_start);
  const endTime = formatGoogleCalendarDate(event.datetime_end);
  const location = event.venue ? encodeURIComponent(
    `${event.venue.name}, ${event.venue.geocoded_address}, ${event.venue.city}, ${event.venue.state}`,
  ) : '';
  const details = encodeURIComponent(event.description || '');
  const title = encodeURIComponent(`${event.title} @ ${event.venue?.name}`);

  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startTime}/${endTime}&details=${details}&location=${location}`;
};

export const AddToGoogleCalendarButton = ({ event }: { event: EventType }) => (
  <IconButton
    color="secondary"
    href={createGoogleCalendarLink(event)}
    target="_blank"
    rel="noopener noreferrer"
    title="Add to Google Calendar"
    sx={{ p: 0 }}
  >
    <EditCalendarOutlinedIcon />
  </IconButton>
);

export const TicketButton = ({ event }: { event: EventType }) =>
  event.tickets_url ? (
    <Link
      href={event.tickets_url}
      title="Buy Tickets!"
      target="_blank"
      rel="noopener noreferrer"
      className="limited-text"
      display="inline-block"
      sx={{ p: 0, color: (theme) => theme.palette.ctaColor?.main }}
    >
      {event.tickets_url}
    </Link>
  ) : null;

export const formatDate = (date: string, datePrior?: string) => {
  const formattedDate = new Date(moment(date).format(dateFormat));

  if (datePrior && isSameDay(date, datePrior)) {
    return formattedDate.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    return formattedDate.toLocaleDateString(undefined, {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }
};

interface CTAButtonProps extends ButtonProps {
  href: string;
  text: string;
  mb?: number
}

export const CTAButton: React.FC<CTAButtonProps> = ({ href, text, mb, ...props }) => {
  return (
    <Button
      variant="contained"
      href={href}
      component={Link}
      sx={{
        backgroundColor: (theme) => theme.palette.ctaColor?.main,
        color: '#fff',
        fontFamily: '"kon-tiki-aloha-jf", sans-serif',
        mb: mb || 0,
        mt: 1,
        p: '5px 10px',
        fontSize: '1.2em',
      }}
      {...props}
    >
      {text}
    </Button>
  );
};


export const useEvents = () => {
  const [filters, setFilters] = useState<FiltersType>(DefaultFilters);
  const [events, setEvents] = useState<GroupedEventsType | null>(null);

  const fetchEvents = async () => {
    const eventsData: EventType[] = await EventsService.getEvents();
    const groupedEventsData = groupEventsByUpcoming(eventsData);
    setEvents(groupedEventsData);
  };
  
  useEffect(() => {
    fetchEvents();
  }, []);

  return {
    filters,
    setFilters,
    events,
    setEvents
  }
}