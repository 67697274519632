import { Typography, Box, Card, CardContent } from '@mui/material';
import { formatDate } from './Utils';
import { EventImage } from './EventsShared';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link as RouterLink } from 'react-router-dom';
import { Event as EventType } from '../types';

const EventMiniCard = ({ event }: { event: EventType }) => (
  <Card key={event.id} sx={{ display: 'flex', marginBottom: 2 }}>
    <EventImage event={event} sx={{ width: '200px' }} />
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        <RouterLink to={`/event/${event.id}`}>
          <Typography variant="h5">{event.title}</Typography>
        </RouterLink>

        <Typography variant="subtitle1" color="textSecondary">
          {formatDate(event.datetime_start)}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          display="flex"
          alignItems="center"
        >
          <LocationOnIcon fontSize="small" />
          {event.venue?.name}
        </Typography>
      </CardContent>
    </Box>
  </Card>
);

export default EventMiniCard;
