import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box, Link, Typography } from '@mui/material';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LoadScript } from '@react-google-maps/api';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AboutUsCard from './AboutUs';
import './App.scss';
import CustomAdminRoutes from './components/admin/CustomAdminRoutes';
import ArtistDetail from './components/ArtistDetail';
import EventDetail from './components/EventDetail';
import VenueDetail from './components/VenueDetail';
import { Contact } from './Contact';
import ErrorEl from './Error';
import { MainContainer } from './MainContainer';
import { Main } from './pages/Main';
import EmbedEvents from './pages/EmbedEvents';
import { Instagram, FacebookRounded } from '@mui/icons-material';
import ArtistListingPage from './components/pages/ArtistListingPage';
import VenueListingPage from './components/pages/VenueListingPage';
export const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
export const dateFormat = 'YYYY-MM-DDTHH:mm';

export function Copyright() {
  return (
    <Typography
      variant="body2"
      component="div"
      color="text.secondary"
      align="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        pt: 4,
        flexDirection: {
          xs: 'column',  // Stack vertically on mobile
          sm: 'row',     // Align horizontally on small screens and up
        },
        textAlign: {
          xs: 'center',  // Center text on mobile
          sm: 'inherit', // Inherit alignment on larger screens
        },
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
        <Link p={0.5} mt="8px" sx={{ color: 'black' }} href="https://instagram.com/roguevalley.events">
          <Instagram />
        </Link>
        <Link p={0.5} mt="8px" sx={{ color: 'black' }} href="https://www.facebook.com/TheRogueValleyEvents">
          <FacebookRounded />
        </Link>
        Copyright&nbsp;
        {new Date().getFullYear()}&nbsp;&copy;&nbsp;
        <Link color="inherit" href="https://roguevalley.events">
          RogueValley.Events
        </Link>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        &nbsp; Powered by &nbsp;
        <Link color="inherit" href="https://berad.tech">
          Be Rad Technology
        </Link>
      </Box>
    </Typography>
  );
}
declare module '@mui/material/styles' {
  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface PaletteColor {
    font?: string;
  }

  interface SimplePaletteColorOptions {
    font?: string;
  }

  interface Palette {
    mainBackground?: Palette['primary'];
  }

  interface PaletteOptions {
    mainBackground?: PaletteOptions['primary'];
  }
  interface Palette {
    ctaColor?: Palette['primary'];
  }

  interface PaletteOptions {
    ctaColor?: PaletteOptions['primary'];
  }
}
export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: '"Sintony", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"kon-tiki-aloha-jf", sans-serif',
      fontWeight: '800',
      marginBottom: '.75em',
      fontSize: '36px',
    },
    h2: {
      fontFamily: '"Faustina", serif',
      marginBottom: '12px',
      fontWeight: '800',
    },
    h3: {
      marginBottom: '12px',
    },
    h4: {
      fontFamily: '"kon-tiki-aloha-jf", sans-serif',
      marginBottom: '.25em',
    },
    h5: {
      fontFamily: '"kon-tiki-aloha-jf", sans-serif',
      marginBottom: '.25em',
    },
    h6: {
      fontFamily: '"kon-tiki-aloha-jf", sans-serif',
      marginBottom: '.25em',
    },
  },
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#000',
    },
    secondary: {
      main: '#000',
    },
    mainBackground: {
      main: '#fff',
    },
    ctaColor: {
      main: '#ef4426',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 10px #e1e1e1',
        },
      },
    },
  },
};
const mdTheme = createTheme(themeOptions);
export { mdTheme };

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <MainContainer content={<Main />} />,
      errorElement: <MainContainer content={<ErrorEl />} />,
    },
    {
      path: '/contact',
      element: <MainContainer content={<Contact />} />,
    },
    {
      path: '/about',
      element: <MainContainer content={<AboutUsCard />} />,
    },
    {
      path: '/event/:id',
      element: <MainContainer content={<EventDetail />} />,
    },
    {
      path: '/venues',
      element: <MainContainer content={<VenueListingPage />} />,
    },
    {
      path: '/venue/:id',
      element: <MainContainer content={<VenueDetail />} />,
    },
    {
      path: '/artists',
      element: <MainContainer content={<ArtistListingPage />} />,
    },
    {
      path: '/artist/:id',
      element: <MainContainer content={<ArtistDetail />} />,
    },
    {
      path: '/manage/*',
      element: <CustomAdminRoutes />,
    },
    {
      path: '/embed/:type/:id',
      element: <EmbedEvents />,
    },
  ]);
  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={GoogleMapsApiKey}
      libraries={['places']}
    >
      <ScopedCssBaseline>
        <ThemeProvider theme={mdTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <RouterProvider router={router} />
          </LocalizationProvider>
        </ThemeProvider>
      </ScopedCssBaseline>
    </LoadScript>
  );
}

export default App;
